$( document ).ready(function() {
    $(".goods-gallery__img").click(function(){	// событие клика на маленькое изображение
    	var img = $(this);	// получение картники, на которую кликнули
    	var src = img.attr('src'); // извлечение пути до картинки
    	$('.goods-close').show(	); // вывод закрывающей кнопки
    	$("body").append("<div class='popup'>"+ //добавление разметки всплывающего окна
    						"<div class='popup_bg'></div>"+ // блок - фон затемнение
    						"<img src='"+src+"' class='popup_img' />"+ // увеличенное фото
    						"</div>"); 
    	$(".popup").fadeIn(200); // вывод изображения
    	$(".popup_bg, .goods-close").click(function(){	// событие клика на затемненный фон	
    		$('.goods-close').hide(); // сктрытие закрывающей кнопки
    		$(".popup").fadeOut(200);	//скрытие всплывающего окна
    		setTimeout(function() {
    			$(".popup").remove(); // удаление разметки всплывающего окна
    		}, 200);
    	});
    });
    $('.feedback-form__input_phone').mask('0 (000) 000-00-00'); //маска телефонного номера
    
    
    
    $('#phone_form').submit(function(){ 
        $.post(
            '/post.php', // адрес обработчика
             $("#phone_form").serialize(), // отправляемые данные          
            
            function(msg) { // получен ответ сервера  
                $('#phone_form').hide('slow'); // скрытие формы
                $('#phone_message').html(msg); // вывод сообщения на страницу
            }
        );
        return false;
    });
});